.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 0px;
  cursor: pointer;
  transition: color 0.3s;
  padding-left: 0px;
  margin-left: -50px;
}

.trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.content-background {
  margin: 24px 16px;
  padding: 24px;
  min-height: 850px;
  min-width: 1000px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}
